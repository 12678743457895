const state = {
  model: 0,
  channel: null,
  auth: null,
}
const mutations = {}
let player, video
function addEvents() {
  player.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, (e) => {
    // ICE 协商出错
    console.debug('>>> shout ICE 协商出错')
    console.debug(e)
  })

  player.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, (e) => {
    // 获取到了远端流，可以播放
    console.debug('>>> shout 播放成功')
    console.debug(e)
  })

  player.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, (e) => {
    // offer anwser 交换失败
    console.debug('>>> shout offer anwser 交换失败')
    console.debug(e)
    stop()
  })

  player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, (s) => {
    // 获取到了本地流
    console.debug('>>> shout 获取到了本地流')
    console.debug(s)
  })

  player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, (s) => {
    // 获取本地流失败
    $app.$message.error('获取本地流失败')
    console.debug('>>> shout 获取本地流失败')
    console.debug(s)
  })

  player.on(ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE, (state, event) => {
    // RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
    console.debug('>>> shout RTC 状态变化', state, event)
    if (state === 'connected') {
      $app.$store.dispatch('shout/start')
    }
  })

  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN, (event) => {
    console.debug('>>> shout rtc datachannel 打开 :', event)
  })

  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG, (event) => {
    console.debug('>>> shout rtc datachannel 消息 :', event)
  })

  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR, (event) => {
    console.debug('>>> shout rtc datachannel 错误 :', event)
  })

  player.on(ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE, (event) => {
    console.debug('>>> shout rtc datachannel 关闭 :', event)
  })
}

function stop() {
  if (player) {
    player.close()
    player = null
  }
  if (video && video.srcObject) {
    video.srcObject = null
    video.load()
  }
}

async function play({ commit, dispatch, getters, rootGetters, rootState, state }, { channel, auth }) {
  stop()
  let resolution // = await ZLMRTCClient.GetSupportCameraResolutions()
  resolution = resolution ? resolution[0] : undefined

  let option = {
    element: video,

    recvOnly: false,
    audioEnable: true,
    videoEnable: false,

    useCamera: false,
    usedatachannel: false,
    simulcast: false,

    debug: true,
    resolution: resolution ? { w: resolution.width, h: resolution.height } : { w: 0, h: 0 },

    type: 'shout',
    playParam: {
      app: auth.app,
      sign: auth.sign,
      streamId: auth.stream,
      type: auth.type,
    },
  }

  player = new ZLMRTCClient.Endpoint(option)
  addEvents()
}

function beforeCheck() {
  // if (player) {
  //   let params = {
  //     channelId: $app.$store.state.shout.channel.channelId,
  //     deviceId: $app.$store.state.shout.channel.deviceId,
  //     hasAudio: false,
  //   }
  //   $app.$service.post(process.env.VUE_APP_API + '/gb/device/enableAudio', params)
  // }
  stop()
}

const actions = {
  setup({ commit, dispatch, getters, rootGetters, rootState, state }, { channel, mode }) {
    if (!video) {
      video = $app.$videoEle
    }
    beforeCheck()

    let params = {
      channelId: channel.channelId,
      deviceId: channel.deviceId,
      hasAudio: true,
    }
    $app.$service.post(process.env.VUE_APP_API + '/gb/device/enableAudio', params)
    return $app.$service
      .post(process.env.VUE_APP_API + '/gb/device/talk/auto', {
        channelId: channel.channelId,
        deviceId: channel.deviceId,
      })
      .then(async (res) => {
        if (res && res.stream) {
          await $app.loadZLMRTCClient()
          state.channel = channel
          state.auth = res
          state.model = mode
          play({ commit, dispatch, getters, rootGetters, rootState, state }, { channel, auth: res })
        }
        return res && res.stream
      })
      .catch((e) => {
        $app.$message.info(e)
      })
  },
  start() {
    return $app.$service
      .post(process.env.VUE_APP_API + '/gb/device/talk/start', {
        channelId: $app.$store.state.shout.channel.channelId,
        deviceId: $app.$store.state.shout.channel.deviceId,
        streamId: $app.$store.state.shout.auth.stream,
        model: $app.$store.state.shout.model,
      })
      .then((res) => {
        if (res === 'OK') {
          $app.$message.info('后台喊话已开启')
        }
      })
      .catch((e) => e)
  },
  close() {
    stop()
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
