export default {
  install: function (Vue) {
    const modulesFiles = require.context('./', true, /\.vue$/)

    modulesFiles.keys().reduce((modules, modulePath) => {
      const def = modulesFiles(modulePath).default
      Vue.component(modulePath.match(/\.\/.*\./)[0].slice(2, -1), def)
    }, {})
  },
}
