class CallMember {
  constructor(option = {}) {
    let member = {
      type: '',
      avId: '',
      uid: 0,
      video: true,
      audio: true,
      streamId: '',
      answer: '',
      isReject: false,
    }
    Object.keys(option).forEach((key) => {
      if (key in member) {
        member[key] = option[key]
      }
    })
    return member
  }
}

class CallRoom {
  constructor(option = {}) {
    let room = {
      type: 'Calling',
      state: '',
      avId: '',
      title: '',
      video: true,
      audio: true,
      isFuture: false,
      startTime: '',
      endTime: '',
      callList: [],
      callMap: {},
      localStream: null,
    }
    Object.keys(option).forEach((key) => {
      if (key in room) {
        room[key] = option[key]
      }
    })
    return room
  }
}

const state = {
  addressBookStatus: false,
  navMenuState:'one',
  navTitle:'',
  fullScreenView:'',
  taskData:'',
  
  itemInfo: {
    id: '',
    message: '',
    name: '',
  },
  sessions: [],
  chatList: [],
  sessionMessage: '',
  call: {
    room: null,
    CallMember,
    CallRoom,
  },
}

const mutations = {
  SET_ITEMINFO: (state, itemInfo) => {
    state.itemInfo = itemInfo
  },
  SET_SESSIONS: (state, sessions) => {
    state.sessions = sessions
  },
  SET_CHATLIST: (state, chatList) => {
    state.chatList = chatList
    sessionStorage.setItem('chatList', JSON.stringify(chatList))
  },
  SET_ADDRESSBOOKSTATUS: (state, addressBookStatus) => {
    state.addressBookStatus = addressBookStatus
  },
  SET_SESSIONMESSAGE: (state, sessionMessage) => {
    state.sessionMessage = sessionMessage
  },
  SET_NAVMENUSTATE: (state, navMenuState) => {
    state.navMenuState = navMenuState
  },
  SET_NAVTITLE : (state, navTitle) => {
    state.navTitle = navTitle
  },
  SET_FULLSCREENVIEW : (state,fullScreenView) => {
    state.fullScreenView = fullScreenView
  },
  SET_TASKDATA : (state,taskData) => {
    state.taskData = taskData
  },
}

const actions = {
  setNavMenuState({ commit },navMenuState) {
    commit('SET_NAVMENUSTATE', navMenuState);
  },
  setNavTitle({ commit },navTitle) {
    commit('SET_NAVTITLE', navTitle);
  },
  setFullScreenView({ commit },fullScreenView) {
    commit('SET_FULLSCREENVIEW', fullScreenView);
  },
  setTaskData({ commit },taskData) {
    commit('SET_TASKDATA', taskData);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
