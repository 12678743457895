<template>
  <el-form-item class="ui-form-item" v-on="$listeners" v-bind="{ ...$attrs }">
    <slot />
  </el-form-item>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-form-item {
  @include prop(margin, 0 0 16 0);
  @include prop(font-size, 1);
  :deep .el-form-item__label {
    font-size: inherit;
    display: flex;
    align-items: center;
    @include prop(font-size, 16);
  }
  :deep .el-form-item__content {
    font-size: inherit;
    width: 100%;
    // .el-icon-circle-close {
    //   transform: translate(-50%, 2%);
    // }
  }
}
</style>
