import Vue from 'vue'

if (!sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
  window.addEventListener('load', function () {
    let messageChannel = new MessageChannel()
    window.postMessage(
      {
        type: 'GMD_GET_LOGIN_PERSISTENCE',
        from: 'gmd.platform',
      },
      '*',
      [messageChannel.port2],
    )
    messageChannel.port1.onmessage = function (e) {
      sessionStorage.setItem('VUE_APP_LOGIN_PERSISTENCE', true)
      location.href = location.origin
    }
  })
}

if (sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
  var VUE_APP_BASE_IP = process.env.VUE_APP_BASE_IP
  var VUE_APP_BASE_API =
    localStorage.GMD_VUE_APP_BASE_API === undefined
      ? '//' + VUE_APP_BASE_IP + ':10004'
      : localStorage.GMD_VUE_APP_BASE_API
  var url = location.protocol + VUE_APP_BASE_API + '/api/v3/account/web/authorize/login'
  var code = process.env.VUE_APP_LOGIN_PERSISTENCE_AUTHORIZATION_CODE
  let messageChannel
  window.addEventListener('message', loginPersistenceMsgHandler)
  window.$loginPersistenceUtils = Vue.prototype.loginPersistenceUtils = {
    getStorage: () => {
      return new Promise(resolve => {
        messageChannel = new MessageChannel()
        window.postMessage(
          {
            type: 'GMD_GET_STORAGE',
            from: 'gmd.platform',
          },
          '*',
          [messageChannel.port2],
        )
        messageChannel.port1.onmessage = function (e) {
          messageChannelMsgHandler(e, function (e) {
            resolve(e.data.data || {})
          })
        }
      })
    },
    toLogin: () => {
      return new Promise(resolve => {
        console.debug('extension toLogin 开始')
        messageChannel = new MessageChannel()
        window.postMessage(
          {
            type: 'GMD_TO_LOGIN',
            from: 'gmd.platform',
            data: {
              url,
              code,
            },
          },
          '*',
          [messageChannel.port2],
        )
        messageChannel.port1.onmessage = function (e) {
          messageChannelMsgHandler(e, function (e) {
            console.debug('extension toLogin 完成')
            resolve(e.data.data || '')
          })
        }
      })
    },
    tokenClear: () => {
      return new Promise(resolve => {
        messageChannel = new MessageChannel()
        window.postMessage(
          {
            type: 'GMD_TOKEN_CLEAR',
            from: 'gmd.platform',
          },
          '*',
          [messageChannel.port2],
        )
        messageChannel.port1.onmessage = function (e) {
          messageChannelMsgHandler(e, function (e) {
            console.debug('extension tokenClear 完成')
            resolve()
          })
        }
      })
    },
  }
}

function messageChannelMsgHandler (e, callback = function () {}) {
  const type = e.data && e.data.type
  if (!type) {
    return
  }
  switch (type) {
    case 'GMD_GET_STORAGE':
      callback(e)
    case 'GMD_TO_LOGIN':
      callback(e)
      break
    case 'GMD_TOKEN_CLEAR':
      callback(e)
      break
  }
}

function loginPersistenceMsgHandler (e) {
  if (!e.data) {
    return
  }
  if (e.data.from !== 'gmd.extensions') {
    return
  }
  const type = e.data.type
  if (!type) {
    return
  }
  switch (type) {
    case 'GMD_GET_LOGIN_PARAMS':
      e.ports[0].postMessage({
        type: 'GMD_GET_LOGIN_PARAMS',
        data: {
          url: url,
          code: code,
        },
      })
      break
    case 'GMD_NOTIFICATION':
      const options = e.data.data.options
      if (window.$app) {
        $app.$message[options.type](options.message)
      } else {
        alert(options.message)
      }
      break
    default:
      break
  }
}
