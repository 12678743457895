<template>
  <el-date-picker ref="picker" class="ui-date-picker" v-on="$listeners" v-bind="{ ...$attrs }">
    <slot />
  </el-date-picker>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-date-picker.el-date-editor--date {
  width: 100% !important;
  :deep .el-input__inner {
    @include prop(padding, 0 48);
    @include prop(border-radius, 0);
    @include prop(height, 42);
    @include prop(line-height, 24);
    @include prop(font-size, 10);
    width: 100% !important;
  }
  :deep .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    @include prop(font-size, 18);
    @include prop(margin, 0);
    @include prop(line-height, 0);
  }
  :deep .el-input__prefix {
    @include prop(left, 8);
  }
  :deep .el-input__suffix {
    @include prop(right, 8);
  }
}
.ui-date-picker.el-date-editor--daterange,
.ui-date-picker.el-date-editor--datetimerange {
  @include prop(padding, 4 14);
  @include prop(border-radius, 0);
  @include prop(height, 42);
  @include prop(line-height, 24);
  @include prop(font-size, 10);
  width: 100% !important;
  :deep .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    @include prop(font-size, 22);
    @include prop(margin, 0);
    @include prop(line-height, 0);
  }
  :deep .el-range-input {
    @include prop(font-size, inherit);
  }
  :deep .el-range-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    @include prop(font-size, inherit);
  }
}
</style>
<style lang="scss">
.el-date-range-picker {
  .el-date-range-picker__header {
    @include prop(font-size, 16);
  }
  .el-date-range-picker__content {
    @include prop(padding, 12 12 0 12);
  }
}
</style>
