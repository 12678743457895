const state = {
  streamInfo: {},
  videoUrl: '',
}

const mutations = {
  SET_STREAMINFO: (state, streamInfo) => {
    state.streamInfo = streamInfo
  },
  SET_VIDEOURL: (state, videoUrl) => {
    state.videoUrl = videoUrl
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
