<template>
  <el-button-group class="ui-button-group" v-on="$listeners" v-bind="{ ...$attrs }">
    <slot />
  </el-button-group>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-button-group {
  display: flex;
}
</style>
