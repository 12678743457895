<template>
  <el-select
    class="ui-select"
    :class="{
      mini: size === 'mini',
    }"
    :size="size"
    v-on="$listeners"
    v-bind="{ ...$attrs }"
  >
    <slot />
  </el-select>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default() {
        return 'medium'
      },
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-select {
  width: 100%;
  @include prop(font-size, 16);
  :deep .el-input__inner,
  :deep .el-textarea__inner {
    font-family: 'Microsoft Yahei';
    @include prop(border-radius, 0);
    @include prop(border-width, 1);
    @include prop(height, 42);
    @include prop(line-height, 24);
    @include prop(padding, 8 12);
    @include prop(font-size, 16);
  }
  &.mini {
    :deep .el-input__inner,
    :deep .el-textarea__inner {
      @include prop(height, 36);
      @include prop(line-height, 24);
      @include prop(padding, 8 12);
      @include prop(font-size, 14);
    }
  }

  :deep .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    @include prop(font-size, 18);
    @include prop(margin, 0);
    @include prop(line-height, 0);
  }
  :deep .el-input__prefix {
    @include prop(left, 8);
  }
  :deep .el-input__suffix {
    @include prop(right, 8);
  }
}
</style>
<style lang="scss">
.el-select-dropdown {
  border-radius: 0;
}
.el-select-dropdown__list {
  padding: 0;
}
</style>
