import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决vue-router 3.0编程式导航提示重复报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: sessionStorage.VUE_APP_LOGIN_PERSISTENCE ? '/deviceCenter' : '/dashboard',
  },
  sessionStorage.VUE_APP_LOGIN_PERSISTENCE
    ? {
        path: '/login',
        name: 'login',
        redirect: '/deviceCenter',
      }
    : {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
      },
  {
    path: '/base',
    hidden: true,
    component: () => import('@/components/layout/base.vue'),
    children: [
      // {
      //   path: '/dashboard',
      //   name: 'dashboard',
      //   component: () => import('@/views/home/dashboard71.vue'),
      // },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/home/online_dashboard.vue'),
      },
      {
        path: '/offline_dashboard',
        name: 'offline_dashboard',
        component: () => import('@/views/home/offline_dashboard.vue'),
      },
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/dashboard.vue'),
      },
      {
        path: '/dashboard2',
        name: '2dashboard',
        component: () => import('@/views/home/dashboard2.vue'),
      },
      {
        path: '/dashboard3',
        name: '3dashboard',
        component: () => import('@/views/home/dashboard3.vue'),
      },
      {
        path: '/dashboard4',
        name: '4dashboard',
        component: () => import('@/views/home/dashboard4.vue'),
      },
      {
        path: '/dashboard5',
        name: '5dashboard',
        component: () => import('@/views/home/dashboard5.vue'),
      },
      {
        path: '/dashboard6',
        name: '6dashboard',
        component: () => import('@/views/home/dashboard6.vue'),
      },
      // {
      //   path: '/digitalMap',
      //   name: 'digitalMap',
      //   component: () => import('@/views/digitalMap/index.vue'),
      // },
      {
        path: '/digitalMap',
        name: 'digitalMap',
        component: () => import('@/views/home/dashboard42.vue'),
      },
      {
        path: '/newDigitalMap',
        name: 'newDigitalMap',
        component: () => import('@/views/home/dashboard41.vue'),
      },
      {
        path: '/mapDigital',
        name: 'mapDigital',
        component: () => import('@/views/mapDigital/index2.vue'),
      },
      // {
      //   path: '/mapDigital',
      //   name: 'mapDigital',
      //   component: () => import('@/views/mapDigital/index.vue'),
      // },
      {
        path: '/deviceCenter',
        name: 'deviceCenter',
        component: () => import('@/views/deviceCenter/index.vue'),
      },
      {
        path: '/iot/:module',
        name: 'iot',
        component: () => import('@/views/iot/index.vue'),
      },
      {
        path: '/iot',
        // name: 'iot',
        redirect: '/iot/index',
      },
      {
        path: '/map/:module',
        name: 'map',
        component: () => import('@/views/map/index.vue'),
      },
      {
        path: '/map',
        // name: 'map',
        redirect: '/map/fence',
      },
      {
        path: '/aiscenes/:module',
        name: 'aiscenes',
        component: () => import('@/views/aiscenes/index.vue'),
      },
      {
        path: '/aiscenes',
        // name: 'aiscenes',
        redirect: '/aiscenes/index',
      },
      {
        path: '/cloudStorage',
        name: 'CloudStorage',
        component: () => import('@/views/cloudStorage/index.vue'),
      },
      {
        path: '/threeJsModel',
        name: 'ThreeJsModel',
        component: () => import('@/views/threeJsModel/index.vue'),
      },
    ],
  },
  { path: '/404', component: () => import('@/views/404/index.vue') },
  { path: '*', redirect: '/404' },
]

routes
  .find(e => e.path === '/base')
  .children.forEach(e => {
    e.beforeEnter = function (to, from, next) {
      if (to.name === 'mapDigital') {
        $app.$store.state.keepAlive.mapDigital = true
      } else if (from.name === 'mapDigital') {
        $app.$store.state.keepAlive.mapDigital = false
      }
      next()
    }
  })

const router = new VueRouter({
  // hash history
  mode: 'history',
  routes,
})

export default router
