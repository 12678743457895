import Service from '@/utils/service.js'

async function _getIp (onIp) {
  const ip = process.env.VUE_APP_BASE_IP
  onIp(ip)
}

async function getIp () {
  return new Promise(resolve => {
    _getIp(ip => {
      resolve(ip)
    })
  })
}

export default async function setIP (Vue) {
  var VUE_APP_BASE_IP = await getIp()
  Vue.prototype.VUE_APP_BASE_IP = VUE_APP_BASE_IP
  Vue.prototype.VUE_APP_BASE_API =
    localStorage.GMD_VUE_APP_BASE_API === undefined
      ? '//' + VUE_APP_BASE_IP + ':10004'
      : localStorage.GMD_VUE_APP_BASE_API
  if (Vue.prototype.VUE_APP_BASE_API.startsWith('//')) {
    Vue.prototype.VUE_APP_BASE_API = location.protocol + Vue.prototype.VUE_APP_BASE_API
  }
  Vue.prototype.VUE_APP_BASE_SOCKET =
    localStorage.GMD_VUE_APP_BASE_SOCKET === undefined
      ? '//' + VUE_APP_BASE_IP + ':8089'
      : localStorage.GMD_VUE_APP_BASE_SOCKET
  if (Vue.prototype.VUE_APP_BASE_SOCKET.startsWith('//')) {
    if (location.protocol === 'https:') {
      Vue.prototype.VUE_APP_BASE_SOCKET = 'wss:' + Vue.prototype.VUE_APP_BASE_SOCKET + '/ws'
    } else {
      Vue.prototype.VUE_APP_BASE_SOCKET = 'ws:' + Vue.prototype.VUE_APP_BASE_SOCKET + '/ws'
    }
  }
  Vue.use(Service)
}
