<template>
  <label class="ui-checkbox-group" v-on="$listeners" v-bind="{ ...$attrs }">
    <slot />
  </label>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-checkbox-group {
  cursor: pointer;
  flex-shrink: 0;
  .el-checkbox {
    @include prop(margin, 0 8 0 0);
  }
}
</style>
